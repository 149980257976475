import React from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import Archives from "./pages/Archives";
import Blog from "./pages/Blog";
import LegalesMention from "./pages/LegalesMention";

import "./styles/index.scss";

const App = () => (
  <div className="App">
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/archives" element={<Archives />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/mentions-légales" element={<LegalesMention />} />
    </Routes>
  </div>
);

export default App;
