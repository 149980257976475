import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const Button = ({ text, className, type, onClick }) => (
  <button className={className} type={type} onClick={onClick}>
    <p className="label">{text}</p>
    {/* <FontAwesomeIcon className="fa-arrow" icon={faChevronRight} />
    <FontAwesomeIcon className="fa-arrow" icon={faChevronRight} /> */}
  </button>
);

export default Button;
