import { useState, useEffect } from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Timeline from "../../components/Timeline";
import Loader from "../../components/Loader";

const Archives = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "Archives";

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="archives-container">
      <Header />
      <h2 className="title">
        <span className="title-span">Archives</span> <br />
        <span className="extra">des</span>{" "}
        <span className="bg-red-archives">Rencontres</span> <br />
        <span className="title-span">Théatrales de</span> <br />
        <span className="bg-red-archives">Savignargues</span>
        <br />{" "}
      </h2>
      <Timeline />
      <Footer />
    </div>
  );
};

export default Archives;
