export const data = [
  {
    date: '2023',
    text1: 'Gazon Maudit - La comédie culte de Josiane Balasko',
    text2: 'Roméo hait Juliette - Version décalée et originale de la célébrissime pièce de Shakespeare',
    text3: 'Manu est Eric partent en Live - Issus de la célèbre troupe des Nous ç Nous',
  },
  {
    date: 'hiver 2022',
    text1: 'Panique au ministère - par les compagnons de la comédie de Lunel',
  },
  {
    date: '2022',
    text1: 'Manon des sources - Ugolin par la Cie Baudra & Co',
    text2: 'Le cas Martin Piche - Aigle noir production',
    text3: 'Grosse Ambiance - La Comédie du Mas',
  },
  {
    date: 'hiver 2020',
    text1: 'Presque marié - Kawa Théâtre',
  },
  {
    date: '2019',
    text1: 'Sandrine Sarroche - woman show',
    text2: 'Nais - de Pagnol par la Cie Baudra & Co',
    text3: '"Conclave Story"',
  },
  {
    date: '2018',
    text1: 'Plié en 4 - Kawa Théatre',
    text2: "J'adore toujours ma femme - Yves Pujol",
    text3: 'Amants à mi-temps - La muse théâtre',
  },
  {
    date: '2017',
    text1: 'Dans la peau de ma femme - 221B Cie',
    text2: 'Moi JE - Antonia de Rendinger',
    text3: "Mariage d'Enfer - 221B Cie",
  },
  {
    date: '2016',
    text1: 'hors programme - spectacle musical - Emmanuelle Brunel accompagnée de Patrice Soletti',
    text2: 'Noces de Rouille - Petites Folies production',
    text3: 'La Femme du Boulanger - Baaudraco & Co',
    text4: "40 ans, c'est moche - MDR comedy records",
  },
  {
    date: '2015',
    text1: 'Entre adultes consentants - Kawa Théatre',
    text2: 'Travail, famille, poterie - Antonia de Rendinger',
    text3: 'Dans ta Bulle - 16-19 Events',
  },
  {
    date: '2014',
    text1: 'Dans la peau d\'une bombe - Baudrac & Co "César" d\'après Marcel Pagnol',
  },
  {
    date: '2013',
    text1: 'Dans Le Bain - Les Zindésirables',
    text2: 'Tout baigne',
    text3: 'Le Coach',
  },
  {
    date: '2012',
    text1: 'Sexe, Arnaque et Tartiflette - Cécile Batailler et Fabrice Schwingrouber',
    text2: 'Full Monty - Kama Théâtre',
    text3: 'Psy - La Compagnie C-Komplet',
  },
  {
    date: '2011',
    text1: 'Vacances de Merde .com - Puce Prod',
    text2: "La fille du puisatier - Ciné-Théatre Baudrac & Co d'après Marcel Pagnol",
    text3: 'Journée de M... - Kawa Théâtre',
  },
  {
    date: '2010',
    text1: 'SMAIM dans "Mon dernier avant le prochain" - Rouge Basket Production',
    text2: '"Compagnie des Zèbres" "Les Zindésirables"',
    text3: 'Compagnie "Le grand Melo" , "Coup de blues à l\'hopital" de Christian Dob',
  },
  {
    date: '2009',
    text1: 'Plus c\'est long, Plus c\'est ... - Compagnie "Evènement"',
    text2: 'L\'acta et "Le chalet de l\'horreur de la trouille qui fait peur"',
    text3: 'Ma femme, mon Languedoc et moi - Fabrice Schwingrouber',
  },
  {
    date: '2008',
    text1: 'Cie "Elle fait parler d\'elle" , "Les 4 Deneuve" de Melissa Drigeard et Guillaume Gamain',
    text2: 'Dits et interdits - Marianne Sergent',
    text3: 'Compagnie "Le grand Melo" , "C\'est encore loin la mairie 2" - Christian Dob',
  },
  {
    date: '2007',
    text1: 'Compagnie "Le grand Melo" , "Les plaisirs simples" de Christian Dob',
    text2: '"Petite peste" producton "Mes meilleurs ennuis" de Guillaume Melanie',
    text3: '"Les comiques anti stress "Les Passetout"',
  },
  {
    date: '2006',
    text1: 'Compagnie "Kawa Théâtre" , "Duos sur canapé" de Marc Camoletti',
    text2: 'Compagnie "Du Café de la Gare", "Ma femme s\'appelle Maurice" de Raffy Shart',
    text3: '"Les Flancs Tireurs" , "Ça va être coton !"',
  },
  {
    date: '2005',
    text1: 'Compagnie "La Clape de Gruissan" , "Fanny" de Marcel Pagnol',
    text2: 'Compagnie "Du Mélo", "Rififoin dans les Labours" de Christian Dob',
    text3: 'Boulègue production présente "Les Bonimenteurs" ',
  },
  {
    date: '2004',
    text1: 'Compagnie "L\'Oeuf Bleu" , "Madame Michu ne viendra pas"',
    text2: 'Compagnie "La Clape de Gruissan" , "Marius" de Marcel Pagnol',
  },
  {
    date: '2003',
    text1: 'Compagnie "Du Mélo", "Les Blouses Blanches" de Christian Dob',
    text2: 'Compagnie "Le NOmbril du MOnde", "Piece détachée" de Thierry Buanafuente',
  },
  {
    date: '2002',
    text1: 'Compagnie "Raybaud-Barral , "Le Père Noël est une Ordure"',
    text2: 'Compagnie "Bleu Comme....", "Opération Judas"',
    text3: 'Compagnie "Paradizio", "Un Air de Famille"',
  },
  {
    date: '2001',
    text1: 'Compagnie "Le Théâtre du Miroir", "Un Riche, Trois Pauvres". Création',
    text2: 'Compagnie "Tournée Générale", "Duo Chic, Duo Choc". Création (suite de sketches)',
  },
  {
    date: '2000',
    text1: 'Compagnie "Christian Dob", "C\'est encore loin la Mairie ? ". Création',
    text2: 'Compagnie "Les 3 coups du Brigadier", "Gueules de Rue". Création',
  },
  {
    date: '1999',
    text1: 'Compagnie "La Tripe du Boeuf" , "Petaouchnok"',
    text2: 'Compagnie "Le Théâtre de la Nouvelle Cigale" (amateurs), "Yineka" (adaptation d\'après Aristophane)',
  },
  {
    date: '1998',
    text1: 'Compagnie "Jacques Brière", "Scènes sur Scène"',
    text2: 'Compagnie "Les 3 coups du Brigadier", "Une visite inopportune" (Copi)',
  },
  {
    date: '1997',
    text1: 'Henri Giraud, "Hommage à Coluche"',
    text2: 'Compagnie "Les R.E.N.E.S. " et Daniel Villanova, "Nuit de rire". Création',
  },
  {
    date: '1996',
    text1: 'Compagnie "Gargamela", "Le Bonhomme à Fables". Création',
    text2: 'Compagnie "Les R.E.N.E.S."',
  },
];
