import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import AnchorLink from "react-anchor-link-smooth-scroll-v2";


import Logo from "../../images/logo.png";
import CurtainLeft from "../../images/curtain-left.webp";
import CurtainRight from "../../images/curtain-right.webp";
import ParentsMars from "../../images/affiches/ParentsMars.jpeg";
import PianoFurioso from "../../images/affiches/PianoFurioso.jpeg";
import TocToc from "../../images/affiches/TocToc.png";
import Map from "../../images/plan.png";
import FestivalProgram from "../../images/affiches/festival-program.png";
import FestivalMenu from "../../images/affiches/festival-menu.png";
import Event from "../../images/affiches/event.png";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Button from "../../components/Button";
import Loader from "../../components/Loader";
import Form from "../../components/Form";
import Modal from "../../components/Modal";


const Home = () => {
  const year = new Date();

  const [isLoading, setIsLoading] = useState(true);
  const [isProgramVisible, setIsProgramVisible] = useState(false);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [orientation, setOrientation] = useState(window.innerWidth);

  const handleOrientation = () => {
    if (!window.screen.orientation.angle) {
      setOrientation("portrait");
    } else {
      setOrientation("landscape");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleOrientation);
    return () => window.removeEventListener("resize", handleOrientation);
  }, []);

  useEffect(() => {
    document.title = "Rencontres sous le Cerisier";

    //& à réactiver avant la PROD
    // window.scrollTo({
    //   top: 0,
    //   behavior: "smooth",
    // });

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="home-container">
      <Header />

      {/* //&--------------------------- HERO -------------------------- // */}
      <section
        className={
          orientation === "landscape" || orientation === 568 // iPhone 5/SE
            ? "hero-landscape-568"
            : orientation === "landscape" || orientation === 640 // Galaxy SIII
            ? "hero-landscape-640"
            : orientation === "landscape" || orientation === 667 // iPhone SE/6/7/8
            ? "hero-landscape-667"
            : orientation === "landscape" || orientation === 700 // iPhone 12
            ? "hero-landscape-700"
            : orientation === "landscape" || orientation === 736 // iPhone 6/7/8 Plus
            ? "hero-landscape-736"
            : orientation === "landscape" || orientation === 740 // Galaxy S8
            ? "hero-landscape-740"
            : orientation === "landscape" || orientation === 812 // iPhone X
            ? "hero-landscape-812"
            : orientation === "landscape" || orientation === 844 // iPhone 12 Pro
            ? "hero-landscape-844"
            : orientation === "landscape" || orientation === 851 // Pixel 5
            ? "hero-landscape-851"
            : orientation === "landscape" || orientation === 1024 // iPad Mini // iPad
            ? "hero-landscape-1024"
            : orientation === "landscape" || orientation === 1366 // iPad Pro
            ? "hero-landscape-1366"
            : "hero"
        }
        id="accueil"
      >
        <img
          className="curtain-left"
          src={CurtainLeft}
          alt="rideau gauche ouvert"
        />
        <img
          className="curtain-right"
          src={CurtainRight}
          alt="rideau droit ouvert"
        />

        <div className="content">
          <div className="text-container">
            <h2 className="subtitle">Association culturelle</h2>
            <h3 className="subsubtitle">
              Organisation de festivals, pièces de théatre et évènements divers
            </h3>
            <h1 className="title">Rencontres sous le cerisier</h1>
            <h2 className="subtitle">Savignargues</h2>
            {/* <div className="event">
              <p className="next-event">
                <span className="underline">Prochain évènement</span> :
              </p>
              <p className="event-title">Festival annuel</p>
              <p className="event-date">30 juin, 1 et 2 juillet 2023</p>
            </div> */}
          </div>

          <div className="image-container">
            <img className="logo" src={Logo} alt="logo de l'association" />
            <p className="citation">
              <span className="italic">
                "L'art du théâtre ne prend toute sa signification que lorsqu'il
                parvient à assembler et à unir"
              </span>
              &nbsp; Jean Vilar
            </p>
          </div>
        </div>
        <FontAwesomeIcon className="fa-arrow arrow-up" icon={faChevronDown} />
        <FontAwesomeIcon className="fa-arrow" icon={faChevronDown} />
      </section>

      {/* //&------------------------- ACTUALITY ------------------------- // */}
      <section className="actuality" id="actualité">
        <div className="container">
          <div className="image-container">
            <img
              className="image"
              src={Event}
              alt="Affiche du prochain évènement des Rencontres sous le Cerisier"
              loading="lazy"
            />
          </div>

          <div className="content">
            <h2 className="title">
              <span className="title-span">Prochain</span>{" "}
              <span className="bg-red">évènement</span>
            </h2>
            <div className="text-container">
              <div className="text">
                <p>
                27<sup>ème</sup> édition du festival annuel de l'association Rencontres sous le Cerisier
                </p>

                <p>Et cette année, le festival sera cloturé le 08 juillet par <span className="bold">un coucours de la chanson française suivi d'un spectacle musical</span>. Événement organisé et offert par la mairie de Savignargues 100% gratuit.</p>
              </div>
              <div className="infos">
                <p>
                  05, 06, 07 juillet 2024 à partie de 19h<br />
                </p>
              </div>
              <div>
                <p className="text-2">
                  <span className="bold">Adresse :</span> <br /> 22, route d'Aigremont 30350 Savignargues <br /> <br />
                </p>
              </div>
              <div className="infos">
                <p>Infos et réservations&nbsp;:&nbsp;
                  <a className="phone" href="tel:0632647714">
                  06 32 64 77 14
                  </a>{" "}
                    /&nbsp;
                  <a className="phone" href="tel:0681952662">
                  06 81 95 26 62
                  </a>
                </p>
                <p>Réservations du 1<sup>er</sup> au 30 juin inclus</p>
              </div>
            </div>
          </div>
        </div>
        <AnchorLink href="#réserver" offset={() => 30}>
          <Button
            text="Je réserve mes places"
            className="button reservation-btn-actuality"
          />
        </AnchorLink>
      </section>

      {/* //&-------------------------- AGENDA -------------------------- // */}
      <section className="agenda" id="agenda">
        <div className="content">
          <h2 className="title">
            <span className="bg-red">Agenda</span> <br />
            <span className="title-span">{year.getFullYear()}</span>
          </h2>
          <div className="text-container">
            <div className="text">
              <p>
                <span className="uppercase underline">17 février</span> : Soirée repas spectacle à Saint Théodorit
              </p>
              <p>
                <span className="uppercase underline">
                  05, 06 et 07 juillet
                </span>{" "}
                : 27 ème édition du Festival des Rencontres sous le Cerisier
              </p>
            </div>
            <div className="program">
              <h3 className="title-program">
                À l'affiche du festival de cette année
              </h3>
              <div className="program-content">
                <div className="show-infos">
                  <img
                    className="image-show"
                    src={ParentsMars}
                    alt="affiche de la pièce Les parents viennent de Mars, les enfants du McDo !"
                  />
                  <div className="text-show">
                    <p className="date"> Vendredi 05 juillet 2024</p>
                    <h4 className="title-show">Les parents viennent de Mars, les enfants du McDo !</h4>
                    <p className="details">
                      On adore nos enfants mais parfois
                      "haaaaaaaaaaaaaaaaaaaa"...
                      On adore nos parents mais parfois
                      "haaaaaaaaaaaaaaaaaaaa"....
                      Lorsque les parents regardent leur enfant la seule
                      question qui leur vient à l'esprit c'est "mais de qui a-t-il
                      pris ?"
                      Lui lorsqu'il regarde ses parents, il twitte :"c'est sûr,
                      j'ai été adopté".
                      Bébé, enfant, adolescence, à tous âges ses joies... et
                      ses galères.
                    </p>
                  </div>
                </div>
                <div className="show-infos">
                  <div className="text-show">
                    <p className="date"> Samedi 06 juillet 2024</p>
                    <h4 className="title-show">Piano Furioso</h4>
                    <p className="details">
                      Insatiable baroudeur, à la fois pianiste, chanteur,
                      compositeur, chef d'orchestre auteur et comédien, <span className="bold">Gilles
                      Ramade</span> a fait de la dispersion un art.
                      Piano Furioso est à son image : insolent, surprenant,
                      inclassable. Il bouscule les codes académiques et s'attaque à
                      la bienséance du classique.
                      Un show déluré et hilarant, mené de main de maître par un
                      virtuose partageant sa passion entre Bach, Ray Charles,
                      Gainsbourg et bien d'autres... Toutes les générations rient
                      avec le maestro.
                    </p>
                  </div>
                  <img
                    className="image-show"
                    src={PianoFurioso}
                    alt="affiche de la pièce Piano Furioso"
                  />
                </div>
                <div className="show-infos">
                  <img
                    className="image-show"
                    src={TocToc}
                    alt="affiche de la pièce Toc Toc"
                  />
                  <div className="text-show">
                    <p className="date">Dimanche 07 juillet 2024</p>
                    <h4 className="title-show">Toc Toc</h4>
                    <p className="details">
                      Le Dr Stern, grand spécialiste des " Toc", est en
                      retard. Les six patients qu'il doit recevoir ce jour-là
                      doivent attendre autour d'une partie de Monopoly, et
                      tenter de débuter seuls une thérapie de groupe ou
                      tocs incontrôlables et histoires personnelles vont se
                      mêler!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <Button
              text="Voir le programme complet"
              className="button program-btn"
              onClick={() => setIsProgramVisible(true)}
            /> */}
            {/* <AnchorLink href="#réserver" offset={() => 30}>
              <Button
                text="Je réserve mes places"
                className="button reservation-btn"
              />
            </AnchorLink> */}
          </div>
        </div>
        {isProgramVisible && (
          <Modal
            className={
              orientation === "landscape" ||
              orientation === 568 ||
              orientation === 640 ||
              orientation === 667 ||
              orientation === 700 ||
              orientation === 736 ||
              orientation === 740 ||
              orientation === 812 ||
              orientation === 844 ||
              orientation === 851 ||
              orientation === 1024 ||
              orientation === 1366
                ? "modal-landscape"
                : "modal"
            }
            src={FestivalProgram}
            alt="Programme du festival"
            onClick={() => setIsProgramVisible(false)}
          />
        )}
      </section>

      {/* //&-------------------------- RESERVATION -------------------------- // */}
      <section className="reservation" id="réserver">
        <h2 className="title">
          <span className="bg-red">Réservation</span> <br />
          {/* <span className="title-span">Festival</span> */}
        </h2>
        <div className="content">
          {/* <div className="informations">
            <div className="prices">
              <h4 className="title-informations">Tarif adulte</h4>
              <p className="text">
                Spectacle seul - 1 soir = 11€ <br />
                Repas seul - 1 soir = 14€ <br />
                Forfait repas / spectacle - 1 soir = 23€ <br />
                Forfait spectacle seul - 3 soirs = 30€ <br />
                Forfait repas / spectacle - 3 soirs = 60€ <br />
              </p>
            </div>
            <div className="prices">
              <h4 className="title-informations">Tarif ado (-15 ans)</h4>
              <p className="text">
                Repas seul - 1 soir = 13€ <br />
                Spectacle à l'unité <span className="star">&nbsp;*</span> -
                gratuit
              </p>
            </div>
            <div className="prices">
              <h4 className="title-informations">Tarif enfant </h4>
              <p className="text">
                Repas seul - 1 soir = 6€
                <br />
                Spectacle à l'unité <span className="star">&nbsp;*</span> -
                gratuit
              </p>
            </div>
          </div>
          <p className="nota-bene">
            <span className="star">&nbsp;*</span>
            Le spectacle est gratuit pour les moins de 15 ans mais il est
            <span className="bold"> nécessaire de réserver</span>. Les enfants
            de moins de 10 ans&nbsp;
            <span className="bold">doivent être accompagnés d'un adulte</span>
            &nbsp; afin qu'ils ne perturbent pas le spectacle.
          </p> */}
          <br />

          <h3 className="title-form">
            Formulaire de réservation
            <br />
            <span className="date-reservation">
              {" "}
              Réservations ouvertes du 1<sup>er</sup> au 30 juin inclus
            </span>{" "}
          </h3>

          {/* <p className="nota-bene">Les réservations pour le prochain évènement ne sont pas encore ouvertes</p> */}
          <p className="nota-bene">Merci de télécharger le formulaire de réservation, l'imprimer et le remplir pour nous le faire parvenir.</p>

        <Link to="resa-form.pdf" target="_blank">
          <Button
            text="Voir et télécharger le formualire de réservation"
            className="button menu-btn"
          />
        </Link>

          {/* <Form /> */}

          <h3 className="title-map">Menu repas festival</h3>

          <Button
            text="Voir le menu"
            className="button menu-btn"
            onClick={() => setIsMenuVisible(true)}
          />

          <h3 className="title-map">Adresse Festival</h3>
          <p className="address">22, route d'Aigremont 30350 Savignargues</p>
          <div className="map-container">
            <iframe
              title="map"
              className="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2871.8911179354254!2d4.081932315507781!3d43.96161797911201!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12b43e16b247e167%3A0x12f9e4d319142547!2s22%20Rte%20d&#39;Aigremont%2C%2030350%20Savignargues!5e0!3m2!1sfr!2sfr!4v1685460401430!5m2!1sfr!2sfr"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="route-map">
              <img className="route-map" src={Map} alt="Plan des routes" />
            </div>
          </div>
        </div>
        {isMenuVisible && (
          <Modal
            className={
              orientation === "landscape" ||
              orientation === 568 ||
              orientation === 640 ||
              orientation === 667 ||
              orientation === 700 ||
              orientation === 736 ||
              orientation === 740 ||
              orientation === 812 ||
              orientation === 844 ||
              orientation === 851 ||
              orientation === 1024 ||
              orientation === 1366
                ? "modal-landscape"
                : "modal"
            }
            src={FestivalMenu}
            alt="Menu du festival"
            onClick={() => setIsMenuVisible(false)}
          />
        )}
      </section>

      {/* //&-------------------------- CONTACT -------------------------- // */}
      <section className="contact" id="contact">
        <h2 className="title">
          <span className="title-span">Nous</span> <br />{" "}
          <span className="bg-red">contacter</span>
        </h2>
        <div className="text">
          <p>
            <span className="span-title uppercase">
              <span className="underline title-contact">Mail</span>&nbsp;:
            </span>
            <a href="mailto:theatresouslecerisier@gmail.com">
              &nbsp;theatresouslecerisier@gmail.com
            </a>
          </p>

          <p>
            <span className="span-title uppercase">
              <span className="underline title-contact">Réservations</span>
              &nbsp;:&nbsp;
            </span>
            &nbsp;
            <a className="phone" href="tel:0632647714">
              06 32 64 77 14
            </a>{" "}
            &nbsp;/&nbsp;
            <a className="phone" href="tel:0681952662">
              06 81 95 26 62
            </a>
          </p>

          <p>
            <span className="span-title uppercase">
              <span className="underline title-contact">Technique</span>
              &nbsp;:&nbsp;
            </span>
            &nbsp;
            <a className="phone" href="tel:0699352849">
              06 99 35 28 49
            </a>{" "}
            &nbsp;/&nbsp;
            <a className="phone" href="tel:0602674114">
              06 02 67 41 14
            </a>
          </p>
          <p>
            <span className="span-title uppercase">
              <span className="underline title-contact">Adresse</span>
              &nbsp;:&nbsp;
            </span>

            <span className="address">
              Rencontres sous le Cerisier - 33, rue de la Forge 30350
              Savignargues
            </span>
          </p>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
