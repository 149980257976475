import Cherries from "../../images/loader.webp";

const Loader = () => {
  return (
    <div className="loader-container">
      <img
        className="loader"
        src={Cherries}
        alt="masques de théatre en cerises"
      />
      <div className="dots-container">
        <span className="dots"></span>
        <span className="dots"></span>
        <span className="dots"></span>
      </div>
    </div>
  );
};

export default Loader;
