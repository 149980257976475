import { useEffect } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const LegalesMention = () => {
  useEffect(() => {
    document.title = "Mentions légales";

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Header />
      <div className="legales-container">
        <h1 className="title">
          <span className="title-span">Mentions</span> <br />
          <span className="bg-red">légales</span>
        </h1>

        <p className="text">En cours de rédaction ! 🍒🎭</p>
      </div>
      <Footer />
    </>
  );
};

export default LegalesMention;
