import { useEffect, useState } from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Loader from "../../components/Loader";

const Blog = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "Blog";

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="blog-container">
      <Header />
      <h1 className="title">
        <span className="title-span">Quoi de neuf</span> <br />
        <span className="bg-red">à Savignargues ? </span>
      </h1>

      <p className="text">Page blog bientôt disponible ! 🍒🎭</p>
      <Footer />
    </div>
  );
};

export default Blog;
