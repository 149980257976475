const Modal = ({ className, src, alt, onClick }) => (
  <div className={className}>
    <img className="image" src={src} alt={alt} />
    <div className="cross" onClick={onClick}>
      <span className="text">fermer</span> X
    </div>
  </div>
);

export default Modal;
