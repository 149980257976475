import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";

import Logo from "../../images/logo.png";

const Footer = () => {
  let year = new Date();

  return (
    <>
      <div className="footer-container">
        <div className="content">
          <div className="element logo-container">
            <img
              className="logo-footer"
              src={Logo}
              alt="Logo de l'association"
            />
            <h4 className="title-logo">Rencontres sous le Cerisier</h4>

            <p className="text">
              <span className="copyright">
                &copy; 1996 - {year.getFullYear()} - Tous droits réservés
              </span>
            </p>
          </div>

          <div className="element social-container">
            <p className="social">Retrouvez-nous sur les réseaux</p>
            <div className="icones">
              <a
                href="https://www.facebook.com/rencontresouslecerisier"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="fa fa-facebook" icon={faFacebook} />
              </a>
              <a
                href="https://www.instagram.com/rencontressouslecerisier/"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  className="fa fa-instagram"
                  icon={faInstagram}
                />
              </a>
            </div>
          </div>

          <Link className="element links-container" to="/mentions-légales">
            <p className="legales-link">Mentions légales</p>
          </Link>
        </div>
      </div>
      <div className="fidia">
        Made with 🍒 and 🎵 by{" "}
        <a
          className="underline"
          href="https://fidia.dev"
          target="_blank"
          rel="noreferrer"
        >
          Fidia
        </a>
      </div>
    </>
  );
};

export default Footer;
