import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import AnchorLink from "react-anchor-link-smooth-scroll-v2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";

import Logo from "../../images/logo.png";

function Header() {
  const { pathname } = useLocation();
  const heightScreen = window.innerHeight;
  const year = new Date();

  const [fixed, setFixed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = window.scrollY;

    if (scrolled > heightScreen) {
      setFixed(true);
    } else {
      setFixed(false);

      if (pathname !== "/") {
        setFixed(true);
      }
    }
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <header
      className={
        fixed
          ? "header-container"
          : pathname === "/"
          ? "header-container header-hidden"
          : "header-container header-display"
      }
    >
      <div className="logo-container">
        <NavLink to="/">
          <img className="logo-img" src={Logo} alt="logo du site" />
        </NavLink>
        {/* <h2 className="title-logo">Rencontres sous le Cerisier</h2> */}
      </div>

      <div className="contact-container">
        <ul className="contact-list">
          <a
            href="mailto:theatresouslecerisier@gmail.com"
            className="email-ctn"
          >
            <FontAwesomeIcon className="fa fa-email" icon={faEnvelope} />
            <span className="span-mail">theatresouslecerisier@gmail.com</span>
          </a>

          <a
            href="https://www.facebook.com/rencontresouslecerisier"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon className="fa fa-facebook" icon={faFacebook} />
          </a>
          <a
            href="https://www.instagram.com/rencontressouslecerisier/"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon className="fa fa-instagram" icon={faInstagram} />
          </a>
        </ul>
      </div>

      <button
        className="burger-container"
        onClick={() => {
          setMenuVisible(!menuVisible);
        }}
      >
        {menuVisible ? (
          <div className="cross">X</div>
        ) : (
          <div className="burger"></div>
        )}
      </button>

      <nav
        className={
          menuVisible ? "navigation-container-mobile" : "navigation-container"
        }
      >
        <ul className="nav-list">
          <li className="nav-item">
            {pathname !== "/" ? (
              <NavLink to="/">Accueil</NavLink>
            ) : (
              <AnchorLink href="#accueil">Accueil</AnchorLink>
            )}
          </li>

          <li className="nav-item">
            {pathname !== "/" ? (
              <NavLink to="/">À la une</NavLink>
            ) : (
              <AnchorLink href="#actualité" offset={() => -10}>
                À la une
              </AnchorLink>
            )}
          </li>

          <li className="nav-item">
            {pathname !== "/" ? (
              <NavLink to="/">
                Agenda &nbsp;
                {year.getFullYear()}
              </NavLink>
            ) : (
              <AnchorLink href="#agenda" offset={() => 60}>
                Agenda &nbsp;
                {year.getFullYear()}
              </AnchorLink>
            )}
          </li>

          {/* <li className="nav-item">
            {pathname !== "/" ? (
              <NavLink to="/">Qui sommes-nous ?</NavLink>
            ) : (
              <AnchorLink href="#apropos" offset="0">
                Qui sommes-nous ?
              </AnchorLink>
            )}
          </li>

          <li className="nav-item">
            {pathname !== "/" ? (
              <NavLink to="/">Faire un don</NavLink>
            ) : (
              <AnchorLink href="#donation" offset="0">
                Faire un don
              </AnchorLink>
            )}
          </li> */}

          <li className="nav-item">
            {pathname !== "/" ? (
              <NavLink to="/">Réservation</NavLink>
            ) : (
              <AnchorLink href="#réserver" offset={() => 30}>
                Réservation
              </AnchorLink>
            )}
          </li>

          <li className="nav-item">
            {pathname !== "/" ? (
              <NavLink to="/">Contact</NavLink>
            ) : (
              <AnchorLink href="#contact" offset={() => 70}>
                Contact
              </AnchorLink>
            )}
          </li>

          <li className="nav-item">
            <NavLink to="/archives">Archives</NavLink>
          </li>

          <li className="">
            <NavLink
              to="/blog"
              className={({ isActive }) =>
                isActive ? "blog-link-active" : "nav-item blog-link"
              }
            >
              Blog
            </NavLink>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
